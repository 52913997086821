import _toConsumableArray from "@babel/runtime/helpers/esm/toConsumableArray";
import * as React from 'react';
import Command from './Command';
import Sh from './sh';
import { jsx as ___EmotionJSX } from "@emotion/react";
var Shell = function Shell() { var _React$useState = React.useState(Sh), sh = _React$useState[0], _ = _React$useState[1]; var _React$useState2 = React.useState([]), previousCommands = _React$useState2[0], setPreviousCommands = _React$useState2[1]; var _React$useState3 = React.useState(''), input = _React$useState3[0], setInput = _React$useState3[1]; var handleKeyPress = React.useCallback(function (e) { switch (e.which) {
    case 8: return false;
    case 13:
        setPreviousCommands([].concat(_toConsumableArray(previousCommands), [input]));
        setInput('');
        break;
    default: setInput(input + String.fromCharCode(e.which));
} return undefined; }, [setPreviousCommands, input, setInput]); var handleKeyDown = React.useCallback(function (e) { switch (e.which) {
    case 8:
        if (input.length > 0) {
            setInput(input.substring(0, input.length - 1));
        }
        break;
    default: break;
} }, [input, setInput]); React.useEffect(function () { document.addEventListener('keypress', handleKeyPress); document.addEventListener('keydown', handleKeyDown); return function () { document.removeEventListener('keypress', handleKeyPress); document.removeEventListener('keydown', handleKeyDown); }; }, [handleKeyPress, handleKeyDown]); React.useEffect(function () { window.scrollTo(0, document.body.scrollHeight); }, [previousCommands]); return ___EmotionJSX("div", null, previousCommands.map(function (c) { return ___EmotionJSX(Command, { key: "" + c, sh: sh, command: c }); }), "$ ", input); };
export default Shell;
